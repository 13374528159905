import React, { useState, useEffect  } from 'react';
import { useQuery } from "@apollo/react-hooks";
import { QUERY_PACIENTES_CANTIDAD } from "../Apollo/Queries/PacienteQueries";
import {  ListaPacientesConFiltro2} from './PacientesConFiltro2'
import {
  Button, 
} from '@material-ui/core';
export function PacientesPaginado2(props) {
  // Polling: provides near-real-time synchronization with
  // your server by causing a query to execute periodically
  // at a specified interval


  const { data: datacantidad, loading, error } = useQuery(
  QUERY_PACIENTES_CANTIDAD,
  {variables: {
    numeroDocumento:props.numeroDocumento,
    idObraSocial:0,
    apellido:props.apellido,
    nombres:props.nombres,
    idTipoDocumento:0,
    numeroAfiliado:props.numeroAfiliado},
  });

  const [s_paginado, sets_paginado] = useState(
    {
      total_registros:0,
      tamanio_pagina:0,
      cant_paginas:0,
    }
  )

  const [s_pagina_actual, sets_pagina_actual] = useState(0)

  useEffect(() => {
    if(datacantidad && datacantidad.pacientesFiltroCount){

      const total_registros = datacantidad.pacientesFiltroCount.cantidad
      const tamanio_pagina = 8
      const cant_paginas = Math.ceil( total_registros / tamanio_pagina)
      const pagina_actual =  (cant_paginas> 0) ? 1:0
      console.log('pacientespaginado_useeffect_sets_paginado')
      console.log('---------------------------------------------------')
      sets_paginado(
        {
          total_registros,
          tamanio_pagina,
          cant_paginas,
        }        
      )
      sets_pagina_actual(pagina_actual)
      
    }
    return () => {
      //
    }

   },[datacantidad])


  
  const updateEstadoPaginado = (pagina_actual) => {
    console.log("updateestadopaginaactual");
    sets_pagina_actual(pagina_actual)
  };
  if (error) return <div style={{ color: 'red' }}>{error.message}</div>;
  if (!datacantidad) return <p>paginado.No hay datos para mostrar</p>;
  // should handle loading status
  if (loading) return <p>Cargando...</p>;

  function NroPagina(props) {
    return (
      (props.nro_pagina === props.pagina_actual)?
        <div>página {props.nro_pagina}</div>:
        <div>
          <Button variant="outlined" onClick={() => {updateEstadoPaginado(props.nro_pagina)}}>{props.nro_pagina}</Button>
        </div>
        
    )
  }
  function PaginadoParametros(props) {
      const lista_paginas = [...Array(s_paginado.cant_paginas).keys()].map(i => i + 1);
      const pagina_actual=s_pagina_actual
      const Paginas =  lista_paginas.map(( nro_pagina ) => (
        <div key={nro_pagina} style={{ display: "inline-block" }}>
          <NroPagina nro_pagina={nro_pagina} pagina_actual={pagina_actual}/>
        </div>
      ));
      return (
        <div>
            {Paginas}
        </div>
      )

  }

  return  <div>
    < ListaPacientesConFiltro2 

      numeroDocumento= {props.numeroDocumento}
      apellido={props.apellido}
      nombres={props.nombres}
      numeroAfiliado={props.numeroAfiliado}

      total_registros={s_paginado.total_registros}
      tamanio_pagina={s_paginado.tamanio_pagina}
      cant_paginas={s_paginado.cant_paginas}
      pagina_actual={s_pagina_actual}     
    />
    < PaginadoParametros />
  </div>
}
