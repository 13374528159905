import React from 'react';
import { useQuery } from 'react-apollo';
import SimpleCardPaciente from './SimpleCardPaciente'
import { QUERY_PACIENTES } from "../Apollo/Queries/PacienteQueries";

export function ListaPacientesConFiltro2(props ) {

  const { data, loading, error } = useQuery(
    QUERY_PACIENTES,
    {variables: {
      numeroDocumento:props.numeroDocumento,
      idObraSocial:0,
      apellido:props.apellido,
      nombres:props.nombres,
      idTipoDocumento:0,
      numeroAfiliado:props.numeroAfiliado,
      debug: JSON.stringify(props),
      first: props.tamanio_pagina,
      skip: (props.pagina_actual * props.tamanio_pagina) - (props.tamanio_pagina) 
      },
      pollInterval: 2500,
    });

  // console.log(s_filtros);
  if (error) return <div style={{ color: 'red' }}>{error.message}</div>;
  if (!data) return <p>confiltro No hay datos para mostrar</p>;
  // // should handle loading status
  if (loading) return <p>Cargando...</p>;
  //  console.log(s_filtros);
   const Cards =  data.pacientesFiltro.map(( paciente ) => (
    <div key={paciente.id} style={{ display: "inline-block" }}>
      <SimpleCardPaciente paciente={paciente}/>
    </div>
  ));
  return  <div>
    {/* total_registros:{props.total_registros}<br />
    tamanio_pagina:{props.tamanio_pagina}<br />
    cant_paginas:{props.cant_paginas}<br />
    pagina_actual:{props.pagina_actual}<br /> */}
    <br />
    {Cards }
  </div>
}
