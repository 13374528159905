import gql from "graphql-tag";

export const QUERY_USUARIOS_DE_LA_CLINICA = gql`
query codigosusuario(
  $idclinica: Int!
  ){
    codigosusuario(
      idclinica: $idclinica
    ){
      usercod
    }
  }
`;