import ApolloClient from "apollo-boost";
import { InMemoryCache } from "apollo-cache-inmemory";
import { CounterMutations } from "./Mutations/CounterMutations";
import { FiltrosMutations} from "./Mutations/PacienteMutations"
const cache = new InMemoryCache();
//Desarrollo:
// export const IMAGENES_BASE = 'http://localhost:8000/hiscliapp/media/'
// export const GRAPHQL_URI = 'http://localhost:8000/graphql/';
//Producción:
export const IMAGENES_BASE = 'https://hisclihu.respuestadigital.com.ar/hiscliapp/media/'
export const GRAPHQL_URI = 'https://hisclihu.respuestadigital.com.ar/graphql/';



const client = new ApolloClient({
    uri: GRAPHQL_URI, // your GraphQL Server 
    request: (operation) => {
        const token = localStorage.getItem('token')
        operation.setContext({
        headers: {
            authorization: token ? `JWT ${token}` : ''
        }
        })
    },
    cache,
    resolvers: {
        Mutation: {
        ...CounterMutations,
        ...FiltrosMutations,
        }
    }
});

const filtrosPaciente = { 
    __typename: 'filtros_paciente',
    numeroDocumento: "",
    nombres:"", 
    apellido:"",
    numeroAfiliado:"",
    total_registros: 0,
    tamanio_pagina: 10,
    cant_paginas: 0,
    pagina_actual: 0

};

const paginadoPaciente = {
    __typename: 'paginado_paciente',
    total_registros: 0,
    tamanio_pagina: 10,
    cant_paginas: 0,
    pagina_actual: 0

};
cache.writeData({
    data: {
      counter: 3,
      filtrosPaciente,
      paginadoPaciente
    },
});    
export default client;