// https://medium.com/@amiya_rbehera/apollo-client-mutation-and-formik-9aec5ddc7b4a
// https://gist.github.com/mwickett/2aecfdeea40daa07d39e11922ae1fe20

import React from 'react';
import { gql } from 'apollo-boost';
import { Formik, Form, Field } from 'formik';
import { Mutation } from "react-apollo";
import * as Yup from 'yup';
// https://stackworx.github.io/formik-material-ui/docs/guide/getting-started
import { TextField,  } from 'formik-material-ui';
import {
  Button, 
  // LinearProgress,
  // MenuItem,
  // FormControl,
  // InputLabel,
  // FormControlLabel,
} from '@material-ui/core';
import logo_redigi from './imagenes/redigi_logo_2020.svg';
import Typography from "@material-ui/core/Typography";
const LOGIN_USER_MUTATION = gql`
  mutation tokenAuth ($username: String!, $password: String!){
    tokenAuth (username: $username, password: $password){
      token
  }
}
`;

const esquema_validacion = Yup.object({
  username: Yup.string()
    .min(5, 'Debe tenér más de 5 caracteres')
    .required('Requerido'),
  password: Yup.string()
    .min(6, 'Debe tener más de 6 caracteres')
    .required('Requerido')
});

const LoginUserFormik = () => (
  <Mutation mutation={LOGIN_USER_MUTATION}>
    {(tokenAuth, {loading}) => (
      <Formik
        initialValues={{
           username: 'maralefer', 
           password: 'Bauty1802'
        }}

        onSubmit={(values, actions) => {
          tokenAuth({ variables: {
            username: values.username,
            password: values.password
          } })
          .then(( data ) => {
            // console.log(data);
            // console.log(data.data.tokenAuth);
            // alert(JSON.stringify(data));
            const  token  = data.data.tokenAuth.token;
            localStorage.setItem('token', token);
            // alert(JSON.stringify(token));
            // alert(JSON.stringify(data));
            // alert(JSON.stringify(values));
          })
          .then(()=>{
            // actions.resetForm();
            alert('Sesión iniciada');
          })
          .catch(error => {
            actions.setFieldError('general', error.message);
          })
          .finally(() => {
            actions.setSubmitting(false);
            
          });
        }}

        validationSchema= {esquema_validacion}
      >
        {props =>(
          <div>
            <Form onSubmit={props.handleSubmit}>
              
              <Field 
                  type="text"
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.username} 
                  name="username" 
                  placeholder="UserName" 
                  component={TextField}
              /> 
              {props.touched.username && props.errors.username ? 
              (<div>{props.errors.username}</div>) : null}
              <Field 
                  type="password"
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.password} 
                  name="password" 
                  placeholder="password" 
                  component={TextField}
              />
              {props.touched.password && props.errors.password ? 
              (<div>{props.errors.password}</div>) : null}
              <div style={{ color: 'red' }}>{props.errors.general}</div>
              <Button 
                variant="contained"
                color="primary"
                disabled={loading}
                // onClick={submitForm}
                type="submit"
              >
                Iniciar la sesión </Button>
            </Form>  
            <Typography>
            <img src={logo_redigi} width="200px" alt="Logo Respuesta Digital" />
            </Typography>      
          </div>
         
        )}
      </Formik>
    )}
  </Mutation>
);

export default LoginUserFormik;
