// https://medium.com/@amiya_rbehera/apollo-client-mutation-and-formik-9aec5ddc7b4a
// https://gist.github.com/mwickett/2aecfdeea40daa07d39e11922ae1fe20
import React, { useState, useEffect  } from 'react';
import { useQuery } from 'react-apollo';
import { Formik, Form, Field } from 'formik';
import { Mutation } from "react-apollo";
import * as Yup from 'yup';
// https://stackworx.github.io/formik-material-ui/docs/guide/getting-started
import { TextField,  } from 'formik-material-ui';
import { CREAR_PACIENTE } from "./Apollo/Mutations/PacienteMutations";
import { QUERY_OBRAS_SOCIALES} from "./Apollo/Queries/ObraSocialQueries";
import { QUERY_LOCALIDADES} from "./Apollo/Queries/LocalidadQueries";
import { QUERY_TIPO_DOCUMENTO} from "./Apollo/Queries/TipoDocumentoQueries";
import { QUERY_TELEFONICA} from "./Apollo/Queries/TelefonicaQueries";
import { QUERY_CLINICAS_DEL_USUARIO} from "./Apollo/Queries/ClinicaQueries";
import { QUERY_PACIENTE_POR_ID } from "./Apollo/Queries/PacienteQueries";
import CustomSelect from "./components/CustomSelect";
import DateFnsUtils from "@date-io/date-fns";
import { useHistory } from 'react-router-dom';


import {
  Button, 
  Box,
} from '@material-ui/core';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";


const esquema_validacion = Yup.object({
  nombres: Yup.string()
    .min(2, 'Debe tener más de 2 caracteres')
    .required('Requeridooo'),
  apellido: Yup.string()
    .min(2, 'Debe tener más de 2 caracteres')
    .required('Requerido'),
  numeroDocumento: Yup.string()
    .required('Requerido'),
  domicilio: Yup.string() 
    .min(2, 'Debe tener más de 2 caracteres')
    .required('Requerido'),
  numeroAfiliado: Yup.string()
    .min(2, 'Debe tener más de 2 caracteres')
    .required('Requerido'),
  fechaNacimiento: Yup.string()
  .required('Requerido'),
  idObraSocial: Yup.string()
    .ensure()
    .required("Requerido"),
  idCelularTelefonica: Yup.string()
    .ensure()
    .required("Requerido"),
  celularNumero: Yup.string()
  .required("Requerido"),
  idClinica: Yup.string()
    .ensure()
    .required("Requerido"),
  idLocalidad:Yup.string()
    .ensure()
    .required("Requerido"),
  idTipoDocumento: Yup.string()
    .ensure()
    .required("Requerido"),
  sexo: Yup.string()
    .required('Requerido'),
});

const PacienteCrearModificar = ({ match: { params: { entidadid, random } } }) =>
  {
    let history = useHistory();
    const { data:datapaciente } = useQuery(
      QUERY_PACIENTE_POR_ID, 
      {variables: {
        pacienteid: entidadid,  
        debug: random  
        }
      });

    const [s_valorinicial, sets_valorinicial] = useState({})

    const { data: dataobrassociales } = useQuery(QUERY_OBRAS_SOCIALES);
    const [s_obrassociales, sets_obrassociales] = useState([])

    const { data: datalocalidades } = useQuery(QUERY_LOCALIDADES);
    const [s_localidades, sets_localidades] = useState([])

    const { data: datatiposdocumento } = useQuery(QUERY_TIPO_DOCUMENTO);
    const [s_tipodocumento, sets_tipodocumento] = useState([])

    const { data: datatelefonicas } = useQuery(QUERY_TELEFONICA);
    const [s_telefonica, sets_telefonica] = useState([])

    const { data: dataclinicasdelusuario } = useQuery(QUERY_CLINICAS_DEL_USUARIO);
    const [s_clinicasdelusuario, sets_clinicasdelusuario] = useState([])  

    useEffect(() => {
      if(dataobrassociales && dataobrassociales.obrassociales) {
        const obrasocial_opciones = dataobrassociales.obrassociales.map( (os) => (
          {
            value:os.id  ,
            label: os.nombre
          }
        ));
        sets_obrassociales(obrasocial_opciones);
      }
    },[dataobrassociales])

    useEffect(() => {
      if(datalocalidades && datalocalidades.localidades) {
        const localidad_opciones = datalocalidades.localidades.map( (loc) => (
          {
            value:loc.id  ,
            label: loc.fullName
          }
        ));
        sets_localidades(localidad_opciones);
      }
    },[datalocalidades])

    useEffect(() => {
      if(datatiposdocumento && datatiposdocumento.tiposdocumento) {
        // console.log(datatiposdocumento.tiposdocumento)
        const tiposdocumento_opciones = datatiposdocumento.tiposdocumento.map( (tipodoc) => (
          {
            value:tipodoc.id,
            label: tipodoc.nombre
          }
        ));
        sets_tipodocumento(tiposdocumento_opciones);
      }
    },[datatiposdocumento])

    useEffect(() => {
      if(datatelefonicas && datatelefonicas.telefonicas) {
        const telefonicas_opciones = datatelefonicas.telefonicas.map( (telefonica) => (
          {
            value:telefonica.id,
            label: telefonica.nombre
          }
        ));
        sets_telefonica(telefonicas_opciones);
      }
    },[datatelefonicas])

    useEffect(() => {
      if(dataclinicasdelusuario && dataclinicasdelusuario.clinicasdelusuario) {
        const clinicasdelusuario_opciones = dataclinicasdelusuario.clinicasdelusuario.map( (clinica) => (
          {
            value:clinica.id,
            label: clinica.nombre
          }
        ));
        sets_clinicasdelusuario(clinicasdelusuario_opciones);
      }
    },[dataclinicasdelusuario])

    useEffect(() => {
      if(datapaciente && datapaciente.pacienteporid) {
        // console.log(datapaciente.pacienteporid)
        // console.log(datapaciente.pacienteporid.tipoDocumento.id)
        // console.log(`fecha nacimientook_inicio: ${datapaciente.pacienteporid.fechanacformato2}.`);

        let valores_iniciales={};
        valores_iniciales={
            apellido: datapaciente.pacienteporid.apellido,
            nombres: datapaciente.pacienteporid.nombres,
            idTipoDocumento: datapaciente.pacienteporid.tipoDocumento.id,
            numeroDocumento: datapaciente.pacienteporid.numeroDocumento,
            idLocalidad:datapaciente.pacienteporid.localidad.id,
            domicilio: datapaciente.pacienteporid.domicilio,
            idCelularTelefonica: datapaciente.pacienteporid.celularTelefonica.id,
            celularNumero: datapaciente.pacienteporid.celularNumero,
            email: datapaciente.pacienteporid.email,
            twitter: datapaciente.pacienteporid.twitter,
            fechaNacimiento: new Date(datapaciente.pacienteporid.fechanacformato2), //'1982/10/09'
            idObraSocial: datapaciente.pacienteporid.obraSocial.id,
            numeroAfiliado: datapaciente.pacienteporid.numeroAfiliado,
            sexo: datapaciente.pacienteporid.sexo,
            idClinica: datapaciente.pacienteporid.clinica.id         
        } 
        sets_valorinicial(valores_iniciales);
      }
    },[datapaciente])

    const sexo_opciones = [
      { value:'F', label: 'Femenino'},
      { value:'M', label: 'Masculino'}
    ]

    const VolverAGestionPacientes = () =>{ 
      history.push('/gestionpacientes');
    };
return (
  <Mutation mutation={CREAR_PACIENTE}>
    {(crearPaciente, {loading}) => (
      <Formik

        initialValues={s_valorinicial}

        onSubmit={(values, actions) => {


          const d = new Date(values.fechaNacimiento)
          const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
          const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d)
          const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)
       
          const fechaNacimiento_formatook = `${da}/${mo}/${ye}`
          // console.log(`fecha nacimientook: ${fechaNacimiento_formatook}.`);
          crearPaciente({ variables: {
            id: entidadid, //en modo CREAR, el id no se usa
            modo: "MODIFICAR",
            apellido: values.apellido,
            nombres: values.nombres,
            idTipoDocumento: values.idTipoDocumento,
            numeroDocumento: values.numeroDocumento,
            idLocalidad: values.idLocalidad,
            domicilio: values.domicilio,
            idCelularTelefonica: values.idCelularTelefonica,
            celularNumero: values.celularNumero,
            email: (values.email)? values.email:"",
            twitter: (values.twitter)? values.twitter: "",
            fechaNacimiento: fechaNacimiento_formatook, //string
            idObraSocial: values.idObraSocial,
            numeroAfiliado: values.numeroAfiliado,
            sexo: values.sexo,
            idClinica: values.idClinica
          } })
          .then(() => {
            // alert('Paciente modificado!' +'\n\n' + JSON.stringify(values, null, 4));
            history.push('/gestionpacientes');
          })
          .catch(error => {
            actions.setFieldError('general', 'Error al enviar formulario:'+error.message);
          })
          .finally(() => {
            actions.setSubmitting(false);      
          });

        }}
        enableReinitialize={true}
        validationSchema= {esquema_validacion}

        render={props =>(
          <Form onSubmit={props.handleSubmit}>
            <Field 
                type="text"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.apellido} 
                name="apellido" 
                placeholder="Apellido" 
                component={TextField}
            /> 
            {props.touched.apellido && props.errors.apellido ? 
            (<div>{props.errors.apellido}</div>) : null}

            <Field 
                type="text"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.nombres} 
                name="nombres" 
                placeholder="Nombres" 
                component={TextField}
            />
            {props.touched.nombres && props.errors.nombres ? 
            (<div>{props.errors.nombres}</div>) : null}

            <Field
              className="custom-select"
              name="idTipoDocumento"
              options={s_tipodocumento}
              component={CustomSelect}
              placeholder="Seleccioná un tipo de documento..."
              isMulti={false}
            />

          <Field 
                type="text"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.numeroDocumento} 
                name="numeroDocumento" 
                placeholder="Número de documento" 
                component={TextField}
            />
            {props.touched.numeroDocumento && props.errors.numeroDocumento ? 
            (<div>{props.errors.numeroDocumento}</div>) : null}

            <Field
              className="custom-select"
              name="idLocalidad"
              options={s_localidades}
              component={CustomSelect}
              placeholder="Selecciona una localidad"
              isMulti={false}
            />


            {props.touched.idLocalidad && props.errors.idLocalidad ? 
            (<div>{props.errors.idLocalidad}</div>) : null}

           <Field 
                type="text"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.domicilio} 
                name="domicilio" 
                placeholder="Domicilio" 
                component={TextField}
            />
            {props.touched.domicilio && props.errors.domicilio ? 
            (<div>{props.errors.domicilio}</div>) : null}

            <Field
              className="custom-select"
              name="idCelularTelefonica"
              options={s_telefonica}
              component={CustomSelect}
              placeholder="Seleccioná una compañía telefónica"
              isMulti={false}
            />

           <Field 
                type="text"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.celularNumero} 
                name="celularNumero" 
                placeholder="Celular" 
                component={TextField}
            />
            {props.touched.celularNumero && props.errors.celularNumero ? 
            (<div>{props.errors.celularNumero}</div>) : null}

           <Field 
                type="text"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.email} 
                name="email" 
                placeholder="Email" 
                component={TextField}
            />
            {props.touched.email && props.errors.email ? 
            (<div>{props.errors.email}</div>) : null}

           <Field 
                type="text"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.twitter} 
                name="twitter" 
                placeholder="Twitter" 
                component={TextField}
            />
            {props.touched.twitter && props.errors.twitter ? 
            (<div>{props.errors.twitter}</div>) : null}

            <Box width="100%" mb={2}>
              {/* Material Ui Date Picker */}
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  id="date-picker-dialog"
                  label="Date picker dialog"
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  value={props.values.fechaNacimiento}
                  onChange={value => props.setFieldValue("fechaNacimiento", value)}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
              </MuiPickersUtilsProvider>
            </Box>

            <Field
              className="custom-select"
              name="idObraSocial"
              options={s_obrassociales}
              component={CustomSelect}
              placeholder="Seleccioná una obra social"
              isMulti={false}
            />

           <Field 
                type="numeroAfiliado"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.numeroAfiliado} 
                name="numeroAfiliado" 
                placeholder="Número de afiliado" 
                component={TextField}
            />
            {props.touched.numeroAfiliado && props.errors.numeroAfiliado ? 
            (<div>{props.errors.numeroAfiliado}</div>) : null}

            <Field
              className="custom-select"
              name="sexo"
              options={sexo_opciones}
              component={CustomSelect}
              placeholder="Seleccioná el sexo biológico"
              isMulti={false}
            />

            <Field
              className="custom-select"
              name="idClinica"
              options={s_clinicasdelusuario}
              component={CustomSelect}
              placeholder="Seleccioná una Clínica"
              isMulti={false}
            />

            <div style={{ color: 'red' }}>{props.errors.general}</div>
            <Button 
              variant="contained"
              color="primary"
              disabled={loading}
              // onClick={submitForm}
              type="submit"
            >
              Modificar paciente
           </Button>
           <Button 
              variant="contained"
              color="primary"
              onClick={VolverAGestionPacientes}
              type="submit"
            >
               Cancelar </Button>
          </Form>
        )}
      />
    )}
  </Mutation>
);
}

export default PacienteCrearModificar;
