import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {IMAGENES_BASE} from "../../Apollo/config";
import ConsultaImagenBotonBorrar from "./ConsultaImagenBorrar";
import ConsultaImagenCompletaDialog from './ConsultaImagenCompletaDialog'
const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    display: "inline-block",
    marginRight: 5, // or sth.
    marginBottom: 5 // or sth.
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
}));

export default function SimpleCardConsultaImagen( props) {
  const classes = useStyles();
  const imagen = props.imagen
  const imagen_url = IMAGENES_BASE + imagen.imagen
  const imagen_titulo = imagen.fechaSubida
  const idimagen = props.imagen.id
  console.log(props.consulta)
  return (

      <Card className={classes.root}>
      <CardContent>
        <ConsultaImagenCompletaDialog imagen_url={imagen_url} imagen_titulo={imagen_titulo}/>
        <ConsultaImagenBotonBorrar idimagen={idimagen}/>
      </CardContent>
    </Card>
  
  );
}