// https://medium.com/@amiya_rbehera/apollo-client-mutation-and-formik-9aec5ddc7b4a
// https://gist.github.com/mwickett/2aecfdeea40daa07d39e11922ae1fe20

import React, { useState, useEffect  } from 'react';
import { useQuery } from 'react-apollo';
import { Formik, Form, Field } from 'formik';
import { Mutation } from "react-apollo";
import * as Yup from 'yup';
// https://stackworx.github.io/formik-material-ui/docs/guide/getting-started
import { TextField,  } from 'formik-material-ui';
import { CREAR_PACIENTE } from "./Apollo/Mutations/PacienteMutations/";
import { QUERY_OBRAS_SOCIALES} from "./Apollo/Queries/ObraSocialQueries/";
import { QUERY_LOCALIDADES} from "./Apollo/Queries/LocalidadQueries";
import { QUERY_TIPO_DOCUMENTO} from "./Apollo/Queries/TipoDocumentoQueries";
import { QUERY_TELEFONICA} from "./Apollo/Queries/TelefonicaQueries";
import { QUERY_CLINICAS_DEL_USUARIO} from "./Apollo/Queries/ClinicaQueries";

import CustomSelect from "./components/CustomSelect";
import DateFnsUtils from "@date-io/date-fns";
import { useHistory } from 'react-router-dom';

import {
  Button, 
  Box,
  // LinearProgress,
  // MenuItem,
  // FormControl,
  // InputLabel,
  // FormControlLabel,
} from '@material-ui/core';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";



const esquema_validacion = Yup.object({
  nombres: Yup.string()
    .min(2, 'Debe tener más de 2 caracteres')
    .required('Requeridooo'),
  apellido: Yup.string()
    .min(2, 'Debe tener más de 2 caracteres')
    .required('Requerido'),
  numeroDocumento: Yup.string()
    .required('Requerido'),
  domicilio: Yup.string() 
    .min(2, 'Debe tener más de 2 caracteres')
    .required('Requerido'),
  numeroAfiliado: Yup.string()
    .min(2, 'Debe tener más de 2 caracteres')
    .required('Requerido'),
  idObraSocial: Yup.string()
    .ensure()
    .required("Requerido"),
  idCelularTelefonica: Yup.string()
    .ensure()
    .required("Requerido"),
  idClinica: Yup.string()
    .ensure()
    .required("Requerido"),
  idLocalidad:Yup.string()
    .ensure()
    .required("Requerido"),
  idTipoDocumento: Yup.string()
    .ensure()
    .required("Requerido"),
  sexo: Yup.string()
    .required('Requerido'),
});

const PacienteCrear = () =>
  {
    let history = useHistory();
    const { data: dataobrassociales } = useQuery(QUERY_OBRAS_SOCIALES);
    const [s_obrassociales, sets_obrassociales] = useState([])

    const { data: datalocalidades } = useQuery(QUERY_LOCALIDADES);
    const [s_localidades, sets_localidades] = useState([])

    const { data: datatiposdocumento } = useQuery(QUERY_TIPO_DOCUMENTO);
    const [s_tipodocumento, sets_tipodocumento] = useState([])

    const { data: datatelefonicas } = useQuery(QUERY_TELEFONICA);
    const [s_telefonica, sets_telefonica] = useState([])

    const { data: dataclinicasdelusuario } = useQuery(QUERY_CLINICAS_DEL_USUARIO);
    const [s_clinicasdelusuario, sets_clinicasdelusuario] = useState([])    

    useEffect(() => {
      if(dataobrassociales && dataobrassociales.obrassociales) {
        const obrasocial_opciones = dataobrassociales.obrassociales.map( (os) => (
          {
            value:os.id  ,
            label: os.nombre
          }
        ));
        sets_obrassociales(obrasocial_opciones);
      }
    },[dataobrassociales])

    useEffect(() => {
      if(datalocalidades && datalocalidades.localidades) {
        const localidad_opciones = datalocalidades.localidades.map( (loc) => (
          {
            value:loc.id  ,
            label: loc.fullName
          }
        ));
        sets_localidades(localidad_opciones);
      }
    },[datalocalidades])

    useEffect(() => {
      if(datatiposdocumento && datatiposdocumento.tiposdocumento) {
        console.log(datatiposdocumento.tiposdocumento)
        const tiposdocumento_opciones = datatiposdocumento.tiposdocumento.map( (tipodoc) => (
          {
            value:tipodoc.id,
            label: tipodoc.nombre
          }
        ));
        sets_tipodocumento(tiposdocumento_opciones);
      }
    },[datatiposdocumento])

    useEffect(() => {
      if(datatelefonicas && datatelefonicas.telefonicas) {
        const telefonicas_opciones = datatelefonicas.telefonicas.map( (telefonica) => (
          {
            value:telefonica.id,
            label: telefonica.nombre
          }
        ));
        sets_telefonica(telefonicas_opciones);
      }
    },[datatelefonicas])

    useEffect(() => {
      if(dataclinicasdelusuario && dataclinicasdelusuario.clinicasdelusuario) {
        const clinicasdelusuario_opciones = dataclinicasdelusuario.clinicasdelusuario.map( (clinica) => (
          {
            value:clinica.id,
            label: clinica.nombre
          }
        ));
        sets_clinicasdelusuario(clinicasdelusuario_opciones);
      }
    },[dataclinicasdelusuario])

    const sexo_opciones = [
      { value:'F', label: 'Femenino'},
      { value:'M', label: 'Masculino'}
    ]

    const VolverAGestionPacientes = () =>{ 
      history.push('/gestionpacientes');
    };
return (
  <Mutation mutation={CREAR_PACIENTE}>
    {(crearPaciente, {loading}) => (
      <Formik
        initialValues={{
           apellido: '',
           nombres: '',
           idTipoDocumento: '',
           numeroDocumento: '',
           idLocalidad: '',
           domicilio: '',
           idCelularTelefonica: '',
           celularNumero: '',
           email: '',
           twitter: '',
           fechaNacimiento: new Date('2009/01/15'),
           idObraSocial: '',
           numeroAfiliado: '',
           sexo: '',
           idClinica: ''
        }}

        onSubmit={(values, actions) => {
          // console.log('submit---------');
          // console.log(values.idObraSocial);
          // console.log(values.sexo)

          const d = new Date(values.fechaNacimiento)
          const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
          const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d)
          const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)
          
          const fechaNacimiento_formatook =`${da}/${mo}/${ye}`
          
          crearPaciente({ variables: {
            id: 0, //en modo CREAR, el id no se usa
            modo: 'CREAR',
            apellido: values.apellido,
            nombres: values.nombres,
            idTipoDocumento: values.idTipoDocumento,
            numeroDocumento: values.numeroDocumento,
            idLocalidad: values.idLocalidad,
            domicilio: values.domicilio,
            idCelularTelefonica: values.idCelularTelefonica,
            celularNumero: values.celularNumero,
            email: values.email,
            twitter: values.twitter,
            fechaNacimiento: fechaNacimiento_formatook,
            idObraSocial: values.idObraSocial,
            numeroAfiliado: values.numeroAfiliado,
            sexo: values.sexo,
            idClinica: values.idClinica
          } })
          .then(() => {
            //alert('Paciente creado'+'\n\n' + JSON.stringify(values, null, 4));
            history.push('/gestionpacientes');
          })
          .catch(error => {
            actions.setFieldError('general', 'Error al enviar formulario:'+error.message);
          })
          .finally(() => {
            actions.setSubmitting(false);      
          });
        }}

        validationSchema= {esquema_validacion}

        render={props =>(
          <Form onSubmit={props.handleSubmit}>

            <Field 
                type="text"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.apellido} 
                name="apellido" 
                placeholder="Apellido" 
                component={TextField}
            /> 

            <Field 
                type="text"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.nombres} 
                name="nombres" 
                placeholder="Nombres" 
                component={TextField}
            />

            <Field
              className="custom-select"
              name="idTipoDocumento"
              options={s_tipodocumento}
              component={CustomSelect}
              placeholder="Seleccioná un tipo de documento..."
              isMulti={false}
            />
            {props.touched.idTipoDocumento && props.errors.idTipoDocumento ? 
            (<div style={{ color: 'red', marginTop: '.5rem' }}>{props.errors.idTipoDocumento}</div>) : null}
            <Field 
                type="text"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.idTipoDocumento} 
                name="numeroDocumento" 
                placeholder="Número de documento" 
                component={TextField}
            />

            <Field
              className="custom-select"
              name="idLocalidad"
              options={s_localidades}
              component={CustomSelect}
              placeholder="Selecciona una localidad"
              isMulti={false}
            />
            {props.touched.idLocalidad && props.errors.idLocalidad ? 
            (<div style={{ color: 'red', marginTop: '.5rem' }}>{props.errors.idLocalidad}</div>) : null}

           <Field 
                type="text"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.domicilio} 
                name="domicilio" 
                placeholder="Domicilio" 
                component={TextField}
            />

            <Field
              className="custom-select"
              name="idCelularTelefonica"
              options={s_telefonica}
              component={CustomSelect}
              placeholder="Seleccioná una compañía telefónica"
              isMulti={false}
            />
           {props.touched.idCelularTelefonica && props.errors.idCelularTelefonica ? 
            (<div style={{ color: 'red', marginTop: '.5rem' }}>{props.errors.idCelularTelefonica}</div>) : null}

           <Field 
                type="text"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.celularNumero} 
                name="celularNumero" 
                placeholder="Celular" 
                component={TextField}
            />

           <Field 
                type="text"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.email} 
                name="email" 
                placeholder="Email" 
                component={TextField}
            />

           <Field 
                type="text"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.twitter} 
                name="twitter" 
                placeholder="Twitter" 
                component={TextField}
            />

            <Box width="100%" mb={2}>
              {/* Material Ui Date Picker */}
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  id="date-picker-dialog"
                  label="Date picker dialog"
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  value={props.values.fechaNacimiento}
                  onChange={value => props.setFieldValue("fechaNacimiento", value)}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
              </MuiPickersUtilsProvider>
            </Box>

            <Field
              className="custom-select"
              name="idObraSocial"
              options={s_obrassociales}
              component={CustomSelect}
              placeholder="Seleccioná una obra social"
              isMulti={false}
            />
           {props.touched.idObraSocial && props.errors.idObraSocial ? 
            (<div style={{ color: 'red', marginTop: '.5rem' }}>{props.errors.idObraSocial}</div>) : null}

           <Field 
                type="numeroAfiliado"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.numeroAfiliado} 
                name="numeroAfiliado" 
                placeholder="Número de afiliado" 
                component={TextField}
            />

            <Field
              className="custom-select"
              name="sexo"
              options={sexo_opciones}
              component={CustomSelect}
              placeholder="Seleccioná el sexo biológico"
              isMulti={false}
            />
           {props.touched.sexo && props.errors.sexo ? 
            (<div style={{ color: 'red', marginTop: '.5rem' }}>{props.errors.sexo}</div>) : null}

            <Field
              className="custom-select"
              name="idClinica"
              options={s_clinicasdelusuario}
              component={CustomSelect}
              placeholder="Seleccioná una Clínica"
              isMulti={false}
            />
           {props.touched.idClinica && props.errors.idClinica ? 
            (<div style={{ color: 'red', marginTop: '.5rem' }}>{props.errors.idClinica}</div>) : null}

            <div style={{ color: 'red' }}>{props.errors.general}</div>
            <Button 
              variant="contained"
              color="primary"
              disabled={loading}
              // onClick={submitForm}
              type="submit"
            >
               Crear paciente </Button>
               <Button 
              variant="contained"
              color="primary"
              onClick={VolverAGestionPacientes}
              type="submit"
            >
               Cancelar </Button>
          </Form>
        )}
      />
    )}
  </Mutation>
);
}





export default PacienteCrear;
