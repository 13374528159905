import React, { useState, useEffect  } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PacienteConsultas from './components/Consulta/PacienteConsultas2'
import { QUERY_PACIENTE_POR_ID } from "./Apollo/Queries/PacienteQueries";
import { useQuery } from 'react-apollo';
function TabPanel(props) {
  const { children, value, index, ...other } = props;


  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    //width: 1200,
  },
}));

export default function PacienteDetalleTabs({ match: { params: { entidadid, random } } }) {
  const { data:datapaciente } = useQuery(
    QUERY_PACIENTE_POR_ID, 
    {variables: {
      pacienteid: entidadid,  
      debug: random  
      }
    });


  const [s_pacientenombre, sets_pacientenombre] = useState('')
  useEffect(() => {
    if(datapaciente && datapaciente.pacienteporid) {
      // console.log(datapaciente.pacienteporid)
      // console.log(datapaciente.pacienteporid.tipoDocumento.id)
      // console.log(`fecha nacimientook_inicio: ${datapaciente.pacienteporid.fechanacformato2}.`);
      const pacientenombre = datapaciente.pacienteporid.apellido+', '+ datapaciente.pacienteporid.nombres
      sets_pacientenombre(pacientenombre);
    }
  },[datapaciente])


  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        {s_pacientenombre}
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
          centered
        >
          <Tab label="Consultas" {...a11yProps(0)} />
          {/* <Tab label="Imágenes" {...a11yProps(1)} />
          <Tab label="Antecedentes personales" {...a11yProps(2)} />
          <Tab label="Antecedentes familiares" {...a11yProps(3)} />
          <Tab label="Examen físico" {...a11yProps(4)} />
          <Tab label="Estudios complementarios" {...a11yProps(5)} /> */}

        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
            <PacienteConsultas pacienteid={entidadid} />
        </TabPanel>
        {/* <TabPanel value={value} index={1} dir={theme.direction}>
          Imágenes
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
            Antecedentes personales
            Entidad:{entidadid} random:{random}
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          Antecedentes familiares
        </TabPanel>
        <TabPanel value={value} index={4} dir={theme.direction}>
          Examen físico
        </TabPanel>
        <TabPanel value={value} index={5} dir={theme.direction}>
          Estudios complementarios
        </TabPanel> */}

      </SwipeableViews>
    </div>
  );
}
