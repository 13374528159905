// fuente:https://codesandbox.io/s/lucid-framework-954jv?fontsize=14&hidenavigation=1&theme=dark&file=/src/components/Appbar.js:0-3572

import React, { useState, Fragment } from "react";
import clsx from "clsx";
import { Router, Route, Link } from "react-router-dom";
import { createBrowserHistory } from "history";

import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";

import LoginUserFormik from '../LoginUserFormik';
import PacienteCrear from '../PacienteCrear';
import PacienteCrearModificar from '../PacienteCrearModificar';
import PacienteDetalleTabs from '../PacienteDetalles';
import { GestionPacientes} from '../GestionPacientes'
import Utestaxios from '../utestaxios';
import Button from '@material-ui/core/Button';
import logo_redigi from '../imagenes/redigi_logo_2020.svg';
const drawerWidth = 240;
const history = createBrowserHistory();

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  flex: {
    flex: 1
  },
  drawerPaper: {
    position: "relative",
    width: drawerWidth
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  toolbarMargin: theme.mixins.toolbar,
  aboveDrawer: {
    zIndex: theme.zIndex.drawer + 1
  }
});

const MyToolbar = withStyles(styles)(({ classes, title, onMenuClick }) => (
  <Fragment>
    <AppBar className={classes.aboveDrawer}>
      <Toolbar>
        <IconButton
          className={classes.menuButton}
          color="inherit"
          aria-label="Menu"
          onClick={onMenuClick}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" color="inherit" className={classes.flex}>
          {title}
        </Typography>
      </Toolbar>
    </AppBar>
    <div className={classes.toolbarMargin} />
  </Fragment>
));

const MyDrawer = withStyles(styles)(
  ({ classes, variant, open, onClose, onItemClick }) => (
    <Router history={history}>
      <Drawer
        variant={variant}
        open={open}
        onClose={onClose}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <div
          className={clsx({
            [classes.toolbarMargin]: variant === "persistent"
          })}
        />
        <List>
          <ListItem
            button
            component={Link}
            to="/"
            onClick={onItemClick("Inicio")}
          >
            <ListItemText>Inicio / Cerrar sesión</ListItemText>
          </ListItem>
          
          <ListItem
            button
            component={Link}
            to="/gestionpacientes"
            onClick={onItemClick("Gestionar pacientes")}
          >
            <ListItemText>Gestionar pacientes</ListItemText>
          </ListItem>
          
          <ListItem
            button
            component={Link}
            to="/login"
            onClick={onItemClick("Iniciar sesión")}
          >
            <ListItemText>Iniciar sesión</ListItemText>
          </ListItem>

        </List>
        
        <img src={logo_redigi} width="30%" alt="Logo Respuesta Digital" />

        <Typography variant="h7" >
          CloudMed RD
        </Typography>
        <Typography variant="h7" >
          Respuesta Digital 
        </Typography>        
        <Typography variant="h7" >
          Copyright 2020 
        </Typography>  
      </Drawer>

 
      <main className={classes.content}>
        {/* <Route exact path="/" component={Home} />
        <Route path="/grid" component={Grid} /> */}


        <Route path='/' exact >
          <div>
            <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    localStorage.setItem('token', '');
                    alert('Sesión finalizada');
                  }}
                >
                  Cerrar la sesión
            </Button>      
            <Typography>
            <img src={logo_redigi} width="200px" alt="Logo Respuesta Digital" />
            </Typography>       
          </div>

        </Route>
        <Route path='/gestionpacientes' component={GestionPacientes} />
        <Route path='/login' component={LoginUserFormik} />
        <Route path='/paciente/:entidadid/:random' component={PacienteCrearModificar} />
        <Route path='/pacientecrear/' component={PacienteCrear} />
        <Route path='/pacientedetalle/:entidadid/:random' component={PacienteDetalleTabs} />
        <Route path='/utestaxios' component={Utestaxios} />
      </main>
    </Router>
  )
);

function AppBarInteraction({ classes, variant }) {
  const [drawer, setDrawer] = useState(false);
  const [title, setTitle] = useState("CloudMed RD");

  const toggleDrawer = () => {
    setDrawer(!drawer);
  };

  const onItemClick = title => () => {
    setTitle(title);
    setDrawer(variant === "temporary" ? false : drawer);
    setDrawer(!drawer);
  };

  return (
    <div className={classes.root}>
      <MyToolbar title={title} onMenuClick={toggleDrawer} />
      <MyDrawer
        open={drawer}
        onClose={toggleDrawer}
        onItemClick={onItemClick}
        variant={variant}
      />
    </div>
  );
}

export default withStyles(styles)(AppBarInteraction);
