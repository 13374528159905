import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import Switch from '@material-ui/core/Switch';
// https://material-ui.com/es/components/material-icons/
import { Smartphone, Mail, Room } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';
import FolderSharedIcon from '@material-ui/icons/FolderShared';
const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    display: "inline-block",
    marginRight: 5, // or sth.
    marginBottom: 5 // or sth.
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

function SexoTexto(props) {
  if (props.sexoChar==="F") {
    return "Femenino";
  }
  return "Masculino";
}

function AtributoEmail(props){
  const email = props.email;
  if (email) {
    return <React.Fragment><Mail /> {email} <br/></React.Fragment>
  } 
  return null;
}

function AtributoTwitter(props){
  const twitter = props.twitter;
  if (twitter) {
    return <React.Fragment><Mail /> {twitter} <br/></React.Fragment>
  } 
  return null;
}
export default function SimpleCard( props) {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;
  const [checked, setChecked] = React.useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };
  
  return (
    
      <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          {props.paciente.obraSocial.nombre}-{props.paciente.numeroAfiliado}
        </Typography>
        <Typography variant="h5" component="h2">
          {props.paciente.apellido}{bull}{props.paciente.nombres}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          {props.paciente.tipoDocumento.nombre} {props.paciente.numeroDocumento}
        </Typography>
      </CardContent>
      <CardActions>
        <Link
            to={`/paciente/${props.paciente.id}/${Math.random().toString(36).replace(/[^a-z]+/g, '')}`}
            key={'paciente_'+props.paciente.id}
        >
          <Button variant="outlined" color="primary" size="small"><EditIcon /></Button>
        </Link>
        <Link
            to={`/pacientedetalle/${props.paciente.id}/${Math.random().toString(36).replace(/[^a-z]+/g, '')}`}
            key={'pacientedetalle_'+props.paciente.id}
        >
          <Button variant="outlined" color="primary" size="small"><FolderSharedIcon /></Button>
        </Link>
        <FormControlLabel
        control={<Switch checked={checked} onChange={handleChange} />}
        label="MÁS"
        />
      </CardActions>
      <Collapse in={checked}>
          <Paper elevation={4} className={classes.paper}>
            <CardContent>
              <Typography variant="body2" component="p">
              Nacimiento: {props.paciente.fechaNacimiento}<br />
              Sexo: <SexoTexto sexoChar={props.paciente.sexo} />
              </Typography>
              <Smartphone /> {props.paciente.celularNumero}<br />
              <AtributoEmail email={props.paciente.email} />
              <AtributoTwitter twitter={props.paciente.twitter} />
              <Room /> {props.paciente.domicilio}<br />
              {props.paciente.localidad.nombre}
            </CardContent>
          </Paper>
      </Collapse>
    </Card>
  
  );
}