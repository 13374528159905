import gql from "graphql-tag";

export const GET_PAGINADO_PACIENTE = gql`
  query GetFiltrosPacienteValue {
    paginadoPaciente @client {
      __typename
    total_registros
    tamanio_pagina
    cant_paginas
    pagina_actual
    }
  }
`;

export const GET_FILTROS_PACIENTE = gql`
  query GetFiltrosPacienteValue {
    filtrosPaciente @client {
      __typename
      numeroDocumento
      nombres
      apellido
      numeroAfiliado
    }
  }
`;

export const QUERY_PACIENTES_CANTIDAD = gql`
 query PacientesFiltroCount(
    $numeroDocumento: String!,
    $idObraSocial: Int!,
    $apellido: String!,
    $nombres: String!,
    $idTipoDocumento: Int!,
    $numeroAfiliado: String,

  ){
		pacientesFiltroCount(numeroDocumento:$numeroDocumento,
    idObraSocial:$idObraSocial,
    apellido:$apellido,
    nombres:$nombres,
    idTipoDocumento:$idTipoDocumento,
    numeroAfiliado:$numeroAfiliado,

   ){
     cantidad
    __typename
    }
  }
`;



const PacienteFiltros_fragments_infopersonal = `
fragment infopersonal on PacienteFiltroType {
    id
    apellido
    nombres
    numeroDocumento
    domicilio
    celularNumero
    email
    twitter
    fechaNacimiento
    fechanacformato2
    numeroAfiliado
    sexo
    clinica {
      id
      nombre
    }
    tipoDocumento {
      id
      nombre
    }
    localidad {
      id
      nombre
    }
    celularTelefonica {
      id
      nombre
    }
    obraSocial {
      id
      nombre
    }
}
`;

export const QUERY_PACIENTES = gql`
  query PacienteFiltros(
      $numeroDocumento: String!,
      $idObraSocial: Int!,
      $apellido: String!,
      $nombres: String!,
      $idTipoDocumento: Int!,
      $numeroAfiliado: String!,
      $first: Int!,
      $skip: Int!,
      $debug: String!,
  ){
		pacientesFiltro(numeroDocumento:$numeroDocumento,
    idObraSocial:$idObraSocial,
    apellido:$apellido,
    nombres:$nombres,
    idTipoDocumento:$idTipoDocumento,
    numeroAfiliado:$numeroAfiliado,
    first:$first,
    skip:$skip,
    debug:$debug,

   ){
     ...infopersonal
    }
  }

  ${PacienteFiltros_fragments_infopersonal}
`;

export const QUERY_PACIENTE_POR_ID = gql`
  query pacienteporid(
    $pacienteid: Int!,
    $debug: String!
  ){
		pacienteporid(
      pacienteid:$pacienteid,
      debug:$debug
   ){
    ...infopersonal
    }
  }

  ${PacienteFiltros_fragments_infopersonal}
`;

const PacienteFiltros_fragments_consultas = `
fragment consultas on PacienteFiltroType {
  pacientemotivoconsultaSet {
    id
    fecha
    fechaformato2
    Observaciones
    consultaimagenSet {
      id
      imagen
      fechaSubida
    }
    paciente {
      id
      clinica {
        id
      }
    }
    motivoConsulta{
      id
      nombre
      tipo
    }
    complicacion {
      id
      nombre
    }
    atendio{
      usuario{
        id
        username
        firstName
        lastName
        email
      }
    }
  }
}
`
export const QUERY_PACIENTE_POR_ID_CONSULTAS = gql`
  query pacienteporid(
    $pacienteid: Int!,
    $debug: String!
  ){
		pacienteporid(
      pacienteid:$pacienteid,
      debug:$debug
   ){
    ...infopersonal
    ...consultas
    }
  }

  ${PacienteFiltros_fragments_infopersonal}

  ${PacienteFiltros_fragments_consultas}
`;