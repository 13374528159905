import gql from "graphql-tag";

export const CONSULTASUBIRIMAGEN = gql`
mutation ConsultaSubirImagen(
   $files: Upload!,
   $idconsulta: Int!){
    ConsultaSubirImagen(
       files:$files,
        idconsulta: $idconsulta
    ){
         idconsulta
    }
}
`;

export const CONSULTABORRARIMAGEN = gql`
mutation ConsultaImagenBorrar ($idimagen:Int!) {
    ConsultaImagenBorrar (idimagen:$idimagen) {
      idimagen
    }
  }
`;

