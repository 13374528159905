import gql from "graphql-tag";

export const QUERY_MOTIVOSCONSULTA = gql`
  query motivosconsulta{
     motivosconsulta{
      id
      nombre
      fullName
    }
  }
`;