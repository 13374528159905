import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import client from "./Apollo/config";
import Appbar from './components/AppBar';
const App = () => {

  return (
    // < MyEnhancedForm />
    <ApolloProvider client={client}>
      <div style={{
        backgroundColor: '#00000008',
        display: 'flex',
        justifyContent:'center',
        alignItems:'center',
        flexDirection: 'column',
      }}>
        < Appbar />
      </div>

    </ApolloProvider>
  );
          }
export default App;