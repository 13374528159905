import React, { Component } from 'react';
import axios from 'axios'
import {GRAPHQL_URI} from "./Apollo/config";

class Utestaxios extends Component {
    state = { 
        data: { imageFile:"" }
     }

     handleImageChange = ({ currentTarget: input}) => { 
        const data = { ...this.state.data};
        data[input.name] = input.files[0];
        console.log(input.name)
        console.log(input.files[0])
        this.setState({ data });
       
      }

      handleImageUpload = async () => {
          const data = new FormData();

          const operations_obj={
            "query": "mutation ConsultaSubirImagen( $files: Upload!,$idconsulta: Int!){ConsultaSubirImagen(files:$files,idconsulta: $idconsulta){idconsulta}}",
            "variables": {
              "files": null,
              "idconsulta": 24
            },
            "operationName": "ConsultaSubirImagen"
          };

          const operations = JSON.stringify(operations_obj);
          data.append("operations", operations);
          data.append('0', this.state.data.imageFile)

          const map = {
            "0": ["variables.files"]
          };
          data.append("map", JSON.stringify(map));

         let result ="";
         const token = localStorage.getItem('token')
          await axios({
              method: 'post',
              url: GRAPHQL_URI,
              data: data,
              headers: {
                'Authorization': 'JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VybmFtZSI6Im1hcmFsZWZlciIsImV4cCI6MTYxMDQ2MTgzOSwib3JpZ0lhdCI6MTYxMDQ2MTUzOX0.R3qzIqwlUOEMUFWAUFCCLzSbgpep5MRnROv6sAduouA',
                'Content-Tranfer-Encoding': 'multipart/form-data',
                'Content-Type': 'application/graphql',
              },
            // withCredentials: true,
          })
          .then(function(response){
              console.log(response);
              result = response;
          })
          .catch(function(response){
              console.log(response)
              result = response;
          })

          return result;

      }

      handleSubmit = async (event) => {
          event.preventDefault();
          const uploadres = await this.handleImageUpload()
          console.log(uploadres.data, 'uploadres')

      }
    render() { 
       
        return ( 
            <React.Fragment>
                <form onSubmit={event => this.handleSubmit(event)}>
                    <div className="form-group">
                        <input name="imageFile" id="image" required type="file" className="form-control"  onChange={this.handleImageChange}/>
                    </div>
                    <button type="submit" className="btn btn-primary">Subir imagen</button>
                </form>
            </React.Fragment>
         );
    }
}
 
export default Utestaxios;