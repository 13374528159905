import gql from "graphql-tag";

import { GET_FILTROS_PACIENTE } from "../../Queries/PacienteQueries";

export const CREAR_PACIENTE = gql`
mutation crearPaciente(
    $id: Int!,
    $modo: String!,
    $apellido: String!,
    $nombres: String!,
    $idTipoDocumento: Int!,
    $numeroDocumento: String!,
    $idLocalidad: Int!,
    $domicilio: String!,
    $idCelularTelefonica: Int!,
    $celularNumero: String!,
    $email: String!,
    $twitter: String!,
    $fechaNacimiento: String!,
    $idObraSocial: Int!,
    $numeroAfiliado: String!,
    $sexo: String!,
    $idClinica: Int!
  ){
    crearPaciente (    
    id:$id,
    modo:$modo,
    apellido : $apellido,
    nombres : $nombres,
    idTipoDocumento : $idTipoDocumento,
    numeroDocumento : $numeroDocumento,
    idLocalidad : $idLocalidad,
    domicilio : $domicilio,
    idCelularTelefonica : $idCelularTelefonica,
    celularNumero : $celularNumero,
    email : $email,
    twitter : $twitter,
    fechaNacimiento : $fechaNacimiento,
    idObraSocial : $idObraSocial,
    numeroAfiliado : $numeroAfiliado,
    sexo : $sexo,
    idClinica : $idClinica) {
        id
        apellido
        nombres
    }
}
`;

export const UPDATE_PAGINADO = gql`
  mutation updatePaginado(
    $total_registros: Int!,
    $tamanio_pagina: Int!,
    $cant_paginas: Int!,
    $pagina_actual: Int!
  ) {
    updatePaginado(
      total_registros:$total_registros,
      tamanio_pagina:$tamanio_pagina ,
      cant_paginas:$cant_paginas,
      pagina_actual: $pagina_actual
    ) @client
    {
      total_registros
    }
  }
`;

export const UPDATE_FILTROS = gql`
  mutation updateFiltros(
    $numeroDocumento: String!,
    $apellido:String!,
    $nombres:String!,
    $numeroAfiliado:String!
  ) {
    updateFiltros(
      numeroDocumento:$numeroDocumento,
      apellido:$apellido,
      nombres:$nombres,
      numeroAfiliado:$numeroAfiliado
    ) @client
    {
      apellido
    }
  }
`;

export const FiltrosMutations = {
  updateFiltros: (_, variables, { cache }) => {
    //query existing data 
    console.log('=======')
    console.log(variables)
    const data = cache.readQuery({ query: GET_FILTROS_PACIENTE });
    //Calculate new counter value
    console.log('filtrosmutation')
    console.log(data)
    const filtrosPaciente = { 
                                  __typename: 'filtros_paciente',
                                  numeroDocumento: variables.numeroDocumento,
                                  apellido: variables.apellido,
                                  nombres: variables.nombres,
                                  numeroAfiliado: variables.numeroAfiliado,
                              };

    console.log('------')
    console.log(filtrosPaciente)                              
    cache.writeData({
      data: { filtrosPaciente }
    });
    return null; //best practice
  }
};