import React, { useState  } from 'react';
import { CONSULTABORRARIMAGEN } from "../../Apollo/Mutations/ConsultaSubirImagenMutations";
import Button from '@material-ui/core/Button';
import {  useMutation } from 'react-apollo';
 const ConsultaImagenBotonBorrar = (props) => {
    const [s_quieroborrar, sets_quieroborrar] = useState(false)
    const [s_imagenborrada, sets_imagenborrada] = useState(false)
    const [s_error, sets_error] = useState('')
    // const [ConsultaImagenBorrar, { loading: deleting, error: deleteError }] = useMutation(CONSULTABORRARIMAGEN);
    const [ConsultaImagenBorrar, { loading: deleting }] = useMutation(CONSULTABORRARIMAGEN);
    const remove = () => {
      if (deleting) return;
      console.log('borrar imagen')
      ConsultaImagenBorrar({
        variables: { idimagen: props.idimagen }
      }).then(({ data }) => {
        // you can do something with the response here
        sets_imagenborrada(true);
      })
      .catch(e => {
        // console.log(e);
        console.log('----error al borrar imagen ----');
        sets_error(e.message)
      })
      ;
    };

    const habilitarborrar = () => {
      sets_quieroborrar(!s_quieroborrar)
    }

    function MostrarError(props){
      if (props.mensaje_error && props.mensaje_error !== ''){
        return <p>{props.mensaje_error}</p>
      }
      return null;
    }

    return (
      <div>
        <Button 
        name="quieroborrar" 
        size="small"
        onClick={habilitarborrar }
        disabled={ deleting || s_imagenborrada}
        color={"#BC0000"}        
        >
            {s_quieroborrar?'No quiero borrar la imagen':'Quiero borrar la imagen'}
        </Button>

        <Button 
        name="borrar" 
        size="small"
        onClick={remove}
        disabled={!s_quieroborrar || deleting || s_imagenborrada}
        color={"#BC0000"}        
        >
            borrar
        </Button>
        <MostrarError mensaje_error={s_error} />
      </div>

    );
  }
  export default ConsultaImagenBotonBorrar;