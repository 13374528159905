// https://medium.com/@amiya_rbehera/apollo-client-mutation-and-formik-9aec5ddc7b4a
// https://gist.github.com/mwickett/2aecfdeea40daa07d39e11922ae1fe20

import React, { useState, useEffect  } from 'react';
import { Formik, Form, Field } from 'formik';
// import * as Yup from 'yup';
// https://stackworx.github.io/formik-material-ui/docs/guide/getting-started
import { TextField,  } from 'formik-material-ui';
import {
  Button, 
  // LinearProgress,
  // MenuItem,
  // FormControl,
  // InputLabel,
  // FormControlLabel,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import FindReplaceIcon from '@material-ui/icons/FindReplace';

import { useQuery, useMutation } from "@apollo/react-hooks";
import { GET_FILTROS_PACIENTE } from "../Apollo/Queries/PacienteQueries";
import {  UPDATE_FILTROS } from "../Apollo/Mutations/PacienteMutations"
import { Mutation } from "react-apollo";
import { PacientesPaginado2 } from './PacientesPaginado2'
import { useHistory } from 'react-router-dom';
// const esquema_validacion = Yup.object({
//   name: Yup.string()
//     .min(5, 'Debe tenér más de 5 caracteres')
//     .required('Requerido'),
//   lastName: Yup.string()
//     .min(6, 'Debe tener más de 6 caracteres')
//     .required('Requerido')
// });

const PacienteFormFiltros = () => {
  let history = useHistory();  
  const [updateFiltros] = useMutation(UPDATE_FILTROS, 
    { variables: { 
      numeroDocumento: '',
      apellido: '',
      nombres: '',
      numeroAfiliado: '',
    }}
  );
  const { data: datafiltros } = useQuery(GET_FILTROS_PACIENTE);
  const [s_filtros, sets_filtros] = useState(datafiltros.filtrosPaciente)

  useEffect(() => {
    if(datafiltros && datafiltros.filtrosPaciente) {
      sets_filtros(datafiltros.filtrosPaciente);
    }
  }, [datafiltros] )

  const LimpiarFiltros = () => {

    updateFiltros()

  };

  const CrearPaciente = () =>{
    history.push('/pacientecrear/');
  };

  return (
    <div>
      <Mutation mutation={UPDATE_FILTROS}>
      {(updateFiltros, {loading}) => (
        <Formik
          enableReinitialize
          initialValues={{
            numeroDocumento: s_filtros.numeroDocumento,
            apellido: s_filtros.apellido,
            nombres: s_filtros.nombres, 
            numeroAfiliado:  s_filtros.numeroAfiliado,
          }}

          onSubmit={(values, actions) => {
            updateFiltros(    
              { variables: { 
                  numeroDocumento: values.numeroDocumento,
                  apellido: values.apellido,
                  nombres: values.nombres,
                  numeroAfiliado: values.numeroAfiliado,   
            }})
            .then(()=>{
              actions.resetForm();
            })
            .catch(error => {
              actions.setFieldError('general', error.message);
            })
            .finally(() => {
              actions.setSubmitting(false);
            });
          }}
        >
          {props =>(
            <div>
              <Form onSubmit={props.handleSubmit}>
                <Field 
                    type="text"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.numeroDocumento} 
                    name="numeroDocumento" 
                    placeholder="Documento" 
                    component={TextField}
                /> 
                {props.touched.numeroDocumento && props.errors.numeroDocumento ? 
                (<div>{props.errors.numeroDocumento}</div>) : null}

                <Field 
                    type="text"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.apellido} 
                    name="apellido" 
                    placeholder="Apellido" 
                    component={TextField}
                />
                {props.touched.apellido && props.errors.apellido ? 
                (<div>{props.errors.apellido}</div>) : null}

                <Field 
                    type="text"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.nombres} 
                    name="nombres" 
                    placeholder="Nombres" 
                    component={TextField}
                />
                {props.touched.nombres && props.errors.nombres ? 
                (<div>{props.errors.nombres}</div>) : null}

                <Field 
                    type="text"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.numeroAfiliado} 
                    name="numeroAfiliado" 
                    placeholder="Número de Afiliado" 
                    component={TextField}
                />
                {props.touched.numeroAfiliado && props.errors.numeroAfiliado ? 
                (<div>{props.errors.numeroAfiliado}</div>) : null}
                <div style={{ color: 'red' }}>{props.errors.general}</div>
                <Button 
                  variant="outlined"
                  color="primary"

                  type="submit"
                >
                  <SearchIcon />
                </Button>
                <Button 
                  variant="outlined"
                  color="primary"
                  type="submit"
                  onClick={LimpiarFiltros}
                  >
                    <FindReplaceIcon />
                </Button>  
                <Button 
                  variant="outlined"
                  color="primary"
                  type="submit"
                  onClick={CrearPaciente}
                  >
                    Crear paciente
                </Button>  

              </Form>

            </div>
          )}
        </Formik>

      )}
      </Mutation>
      <PacientesPaginado2
        numeroDocumento= {s_filtros.numeroDocumento}
        apellido= {s_filtros.apellido}
        nombres= {s_filtros.nombres}
        numeroAfiliado= {s_filtros.numeroAfiliado}
      />
    </div>
  );
}

export default PacienteFormFiltros;
