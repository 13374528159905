// https://medium.com/@amiya_rbehera/apollo-client-mutation-and-formik-9aec5ddc7b4a
// https://gist.github.com/mwickett/2aecfdeea40daa07d39e11922ae1fe20

import React, { useState, useEffect  } from 'react';
import { useQuery } from 'react-apollo';
import { Formik, Form, Field } from 'formik';
import { Mutation } from "react-apollo";
import * as Yup from 'yup';
// https://stackworx.github.io/formik-material-ui/docs/guide/getting-started
import { TextField,  } from 'formik-material-ui';
import { CREAR_CONSULTA } from "../../Apollo/Mutations/ConsultaMutations";
import { QUERY_MOTIVOSCONSULTA} from "../../Apollo/Queries/MotivoConsultaQueries";
import { QUERY_COMPLICACIONES} from "../../Apollo/Queries/ComplicacionQueries";
import { QUERY_USUARIOS_DE_LA_CLINICA } from "../../Apollo/Queries/UsuariosClinicaQueries";

import CustomSelect from "../CustomSelect";
import DateFnsUtils from "@date-io/date-fns";

import {
  Button, 
  Box,
} from '@material-ui/core';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";



const esquema_validacion = Yup.object({
  Observaciones: Yup.string()
    .min(10, 'Debe tener más de 10 caracteres')
    .required('Requerido'),
  // apellido: Yup.string()
  //   .min(2, 'Debe tener más de 2 caracteres')
  //   .required('Requerido'),
  // numeroDocumento: Yup.string()
  //   .required('Requerido'),
  // domicilio: Yup.string() 
  //   .min(2, 'Debe tener más de 2 caracteres')
  //   .required('Requerido'),
  // numeroAfiliado: Yup.string()
  //   .min(2, 'Debe tener más de 2 caracteres')
  //   .required('Requerido'),
});

const ConsultaCrear = (propsDialog) =>
  {

    const { data: datamotivoconsulta } = useQuery(QUERY_MOTIVOSCONSULTA);
    const [s_motivoconsulta, sets_motivoconsulta] = useState([])

    const { data: datacomplicaciones } = useQuery(QUERY_COMPLICACIONES);
    const [s_complicacion, sets_complicacion] = useState([])

    const { data: datausuariosdelaclinica } = useQuery(
      QUERY_USUARIOS_DE_LA_CLINICA,
      {variables: {
        "idclinica": propsDialog.paciente.clinica.id
      }
      });

    const [s_usuariosdelaclinica, sets_usuariosdelaclinica] = useState([])

    useEffect(() => {
      if(datamotivoconsulta && datamotivoconsulta.motivosconsulta) {
        console.log(datamotivoconsulta.motivosconsulta)
        const motivoconsulta_opciones = datamotivoconsulta.motivosconsulta.map( (motivoconsulta) => (
          {
            value:motivoconsulta.id,
            label: motivoconsulta.fullName
          }
        ));
        sets_motivoconsulta(motivoconsulta_opciones);
      }
    },[datamotivoconsulta])

    useEffect(() => {
      if(datacomplicaciones && datacomplicaciones.complicaciones) {
        const complicacion_opciones = datacomplicaciones.complicaciones.map( (complicacion) => (
          {
            value:complicacion.id,
            label: complicacion.nombre
          }
        ));
        sets_complicacion(complicacion_opciones);
      }
    },[datacomplicaciones])

    useEffect(() => {
      if(datausuariosdelaclinica && datausuariosdelaclinica.codigosusuario) {
        console.log(datausuariosdelaclinica.codigosusuario)
        const usuariosdelaclinica_opciones = datausuariosdelaclinica.codigosusuario.map( (usuario) => (
          {
            value:usuario.usercod,
            label: usuario.usercod
          }
        ));
        sets_usuariosdelaclinica(usuariosdelaclinica_opciones);
      }
    },[datausuariosdelaclinica])

return (
  <Mutation mutation={CREAR_CONSULTA}>
    {(crearConsulta, {loading}) => (
      <Formik
        initialValues={{
          fecha: new Date(),
          Observaciones: '',
          idMotivoConsulta: '',
          idComplicacion: '',
          usuarioAtendio: '',
          //atendió traer lista de usuarios logueados, y por defecto que sea el usuario actual
        }}

        onSubmit={(values, actions) => {
          // console.log('submit---------');
          // console.log(values.idMotivoConsulta);
          // console.log(values.idComplicacion);          
          // console.log('---------');

          const d = new Date(values.fecha)
          const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
          const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d)
          const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)
          
          const fecha_formatook =`${da}/${mo}/${ye}`

          crearConsulta({ variables: {
            id: 0, //en modo CREAR, el id no se usa
            modo: 'CREAR',
            idPaciente: propsDialog.paciente.id, //parahacer:traer de props
            fecha: fecha_formatook,
            idMotivoConsulta: values.idMotivoConsulta,
            idComplicacion: values.idComplicacion,
            Observaciones: values.Observaciones,
            usuarioAtendio: values.usuarioAtendio
          } })
          .then(() => {
            //alert('Consulta creada'+'\n\n' + JSON.stringify(values, null, 4));
            //Cerrar el diálogo
            propsDialog.cerrarDialogo()
          })
          .catch(error => {
            actions.setFieldError('general', 'Error al enviar formulario:'+error.message);
          })
          .finally(() => {
            actions.setSubmitting(false);      
          });
        }}

        validationSchema= {esquema_validacion}

        render={props =>(
          <Form onSubmit={props.handleSubmit}>

            <Box width="100%" mb={2}>
              {/* Material Ui Date Picker */}
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  id="date-picker-dialog"
                  label="Date picker dialog"
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  value={props.values.fecha}
                  onChange={value => props.setFieldValue("fecha", value)}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
              </MuiPickersUtilsProvider>
            </Box>

            <Field
              className="custom-select"
              name="idMotivoConsulta"
              options={s_motivoconsulta}
              component={CustomSelect}
              placeholder="Seleccioná un motivo de consulta..."
              isMulti={false}
            />
            {props.touched.idMotivoConsulta && props.errors.idMotivoConsulta ? 
            (<div style={{ color: 'red', marginTop: '.5rem' }}>{props.errors.idMotivoConsulta}</div>) : null}

            <Field
              className="custom-select"
              name="idComplicacion"
              options={s_complicacion}
              component={CustomSelect}
              placeholder="Seleccioná una complicacion (opcional)"
              isMulti={false}
            />
           {props.touched.idComplicacion && props.errors.idComplicacion ? 
            (<div style={{ color: 'red', marginTop: '.5rem' }}>{props.errors.idComplicacion}</div>) : null}

            <Field
              className="custom-select"
              name="usuarioAtendio"
              options={s_usuariosdelaclinica}
              component={CustomSelect}
              placeholder="Seleccioná un usuario de la clínica"
              isMulti={false}
            />
           {props.touched.usuarioAtendio && props.errors.usuarioAtendio ? 
            (<div style={{ color: 'red', marginTop: '.5rem' }}>{props.errors.usuarioAtendio}</div>) : null}

            <Field 
                type="text"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.Observaciones} 
                name="Observaciones" 
                placeholder="Observaciones" 
                component={TextField}
                variant="outlined"
                multiline
            /> 
            {props.touched.Observaciones && props.errors.Observaciones ? 
            (<div style={{ color: 'red', marginTop: '.5rem' }}>{props.errors.Observaciones}</div>) : null}
 
            <div style={{ color: 'red' }}>{props.errors.general}</div>
            <Button 
              variant="contained"
              color="primary"
              disabled={loading}
              // onClick={submitForm}
              type="submit"
            >
               Crear consulta </Button>
          </Form>
        )}
      />
    )}
  </Mutation>
);
}

export default ConsultaCrear;
