import gql from "graphql-tag";

export const CREAR_CONSULTA = gql`
mutation crearConsulta(
    $id: Int!,
    $modo: String!,
    $idPaciente: Int!,
    $fecha: String!,
    $idMotivoConsulta: Int!,
    $idComplicacion: Int!,
    $Observaciones: String!,
    $usuarioAtendio: String!
  ){
    crearConsulta (    
      id:$id,
      modo:$modo,
      idPaciente:$idPaciente,
      fecha: $fecha,
      idMotivoConsulta: $idMotivoConsulta,
      idComplicacion: $idComplicacion,
      Observaciones: $Observaciones,
      usuarioAtendio: $usuarioAtendio) {
        id
        idPaciente
        idMotivoConsulta
        idComplicacion
        Observaciones
        usuarioAtendio
         
    }
}
`;
