import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ConsultaDialogModificar from './ConsultaDialogModificar';
import PacienteConsultaImagenes from '../ConsultaImagenes/PacienteConsultaImagenes';
import Collapse from '@material-ui/core/Collapse';
import Switch from '@material-ui/core/Switch';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ConsultaSubirImagen from '../ConsultaImagenes/ConsultaSubirImagen'
const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    maxWidth: "30%",
    display: "inline-block",
    marginRight: 5, // or sth.
    marginBottom: 5 // or sth.
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

export default function SimpleCardConsulta( props) {
  const classes = useStyles();
  const consulta = props.consulta
  const cantidad_imagenes = props.consulta.consultaimagenSet.length
  //el label solo se muestra si hay una o más imágenes
  const label_imagenes = cantidad_imagenes>1?`${cantidad_imagenes} Imágenes`:'una imagen'
  const [checked, setChecked] = React.useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };
  // console.log(props.consulta)
  return (
    
      <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          {consulta.fecha}
        </Typography>
        <Typography variant="h5" component="h2">
          {consulta.motivoConsulta.nombre}
          {/* {consulta.motivoConsulta.tipo} */}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          {/* {consulta.motivoConsulta.id}  */}
          {(consulta.complicacion && consulta.complicacion.nombre)?consulta.complicacion.nombre:'sin complicación'} <br />
          {consulta.Observaciones} <br />
          {consulta.atendio.usuario.username  }
        </Typography>
      </CardContent>
        <ConsultaDialogModificar consulta={consulta}/>

        {cantidad_imagenes > 0 && (
          <FormControlLabel
          control={<Switch checked={checked} onChange={handleChange} />}
          label={label_imagenes}
          />
        )}

        <Collapse in={checked}>
          <Paper elevation={4} className={classes.paper}>
            <CardContent>
              <PacienteConsultaImagenes consulta={consulta}/>
            </CardContent>
          </Paper>
        </Collapse>
      <ConsultaSubirImagen idconsulta = {consulta.id}/>
    </Card>
  
  );
}