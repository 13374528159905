import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import SimpleCardConsulta from './SimpleCardConsulta'
import { QUERY_PACIENTE_POR_ID_CONSULTAS } from "../../Apollo/Queries/PacienteQueries";
import ConsultaDialogCrear from './ConsultaDialogCrear';
import ConsultaDialogModificar from './ConsultaDialogModificar';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { useQuery } from 'react-apollo';
import CollapsibleTable from './unittest_tablas';
import SimpleCardConsultaImagen from '../ConsultaImagenes/SimpleCardConsultaImagen'
import ConsultaSubirImagen from '../ConsultaImagenes/ConsultaSubirImagen'




const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function createData(consulta) {
  const cantidad_imagenes = consulta.consultaimagenSet.length
  //el label solo se muestra si hay una o más imágenes
    const label_imagenes = cantidad_imagenes>1?`${cantidad_imagenes} Imágenes`:(cantidad_imagenes === 1)?'una imagen':'sin imágenes'

  return {
    fecha: consulta.fecha, //name,
    motivoConsulta: consulta.motivoConsulta.nombre, //calories,
    complicacion: consulta.complicacion.nombre, //fat,
    Observaciones: consulta.Observaciones, //carbs,
    atendio: consulta.atendio.usuario.username, //protein,
    imagenes: consulta.consultaimagenSet, //lista de imágenes
    id: consulta.id, //id de consulta
    label_imagenes, //texto de cantidad de imágenes
    consulta, //toda la consulta, para modificar
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>

      <TableRow className={classes.root}>
        <TableCell>
          {row.label_imagenes}
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell><ConsultaDialogModificar consulta={row.consulta}/></TableCell>
        <TableCell component="th" scope="row">
            {row.fecha}
        </TableCell>
        <TableCell align="left">{row.motivoConsulta}</TableCell>
        <TableCell align="left">{row.complicacion}</TableCell>
        <TableCell align="left">{row.Observaciones}</TableCell>
        <TableCell align="left">{row.atendio}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Imagenes
              </Typography>
                <div>
                <ConsultaSubirImagen idconsulta = {row.id}/>
                  {row.imagenes.map((imagen) => (
                        <div key={imagen.id} style={{ display: "inline-block" }}>
                        <SimpleCardConsultaImagen imagen={imagen}/>
                      </div>
                  ))}
                  
                </div>

            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function PacienteConsultas(props) {
  const {  data, loading, error  } = useQuery(
    QUERY_PACIENTE_POR_ID_CONSULTAS, 
    {variables: {
      pacienteid: props.pacienteid,  
      debug: 'QUERY_PACIENTE_POR_ID_CONSULTAS'  
      },
      pollInterval: 2500,
    });
    
  if (error) return <div style={{ color: 'red' }}>{error.message}</div>;
  if (!data) return <p>confiltro No hay datos para mostrar</p>;
  // // should handle loading status
  if (loading) return <p>Cargando...</p>;
  console.log('PacienteConsultas');
  // console.log(data.pacienteporid.pacientemotivoconsultaSet);
  const paciente = data.pacienteporid
  const lista_consultas= data.pacienteporid.pacientemotivoconsultaSet
  // const lista_consultas_ordenada = lista_consultas.slice().sort((a, b) => a.fechaformato2 - b.fechaformato2)
  const lista_consultas_ordenada = lista_consultas.slice().sort(function compare(a, b) {
    var dateA = new Date(a.fecha);
    var dateB = new Date(b.fecha);
    var resta = dateB - dateA;
    return resta;
  });    

  const rows =  lista_consultas_ordenada.map(( consulta ) => (
    createData(consulta )
  ));

  return (
    <div>

      <ConsultaDialogCrear paciente={paciente}/>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell>Fecha</TableCell>
              <TableCell align="left">Motivo</TableCell>
              <TableCell align="left">Complicación</TableCell>
              <TableCell align="left">Observaciones</TableCell>
              <TableCell align="left">Atendió</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <Row key={row.name} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}